import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import styles from "@one-builder/gatsby-theme-ob-master/src/styles/global.module.scss"

// Components
import Layout from "../components/layout/layout"
import MarkdownContent from "@one-builder/gatsby-theme-ob-master/src/components/content"
import { useGetTokens } from "@one-builder/gatsby-theme-ob-master/src/hooks/useGetTokens"
import convertTokens from "@one-builder/gatsby-theme-ob-master/src/helpers/convertTokens"
// import MarkdownContent from "../components/content"
import setSeoData from "@one-builder/gatsby-theme-ob-master/src/helpers/setSeoData"

export default ({ data, location }) => {
  const { content } = data

  // Get tokens
  const metadata = useGetTokens()

  let seo_data = setSeoData(content.frontmatter)

  return (
    <Layout location={location} seo_data={seo_data}>
      <div className={styles.covidContainer}>
        <div className={styles.intro}>
          <div className={styles.container}>
            <MarkdownContent
              content={convertTokens(
                content.frontmatter.welcomeSection,
                metadata
              )}
            />
            <Link className={styles.primaryBtn} to="/we-are-here-to-help/">
              Learn how we can help
            </Link>
          </div>
        </div>
        <div className={styles.container}>
          <MarkdownContent
            content={convertTokens(
              content.frontmatter.contentSection1,
              metadata
            )}
          />
          <div>
            <iframe
              title="Covid-19"
              id="datawrapper-chart-AlBNV"
              src="https://datawrapper.dwcdn.net/aJFtO/"
              scrolling="no"
              allowtransparency="true"
              height="500"
              width="1440"
              frameborder="0"
            ></iframe>
          </div>
          <MarkdownContent
            content={convertTokens(
              content.frontmatter.contentSection2,
              metadata
            )}
          />
          <MarkdownContent
            content={convertTokens(
              content.frontmatter.contentSection3,
              metadata
            )}
          />
          <MarkdownContent
            content={convertTokens(
              content.frontmatter.contentSection4,
              metadata
            )}
          />
          <MarkdownContent
            content={convertTokens(
              content.frontmatter.contentSection5,
              metadata
            )}
          />
          <Link className={styles.primaryBtn} to="/we-are-here-to-help/">
            Learn how we can help
          </Link>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    content: markdownRemark(frontmatter: { title: { eq: "Covid-19" } }) {
      id
      html
      frontmatter {
        meta {
          title
          description
          page_visible
        }
        title
        template
        welcomeSection
        contentSection1
        contentSection2
        contentSection3
        contentSection4
        contentSection5
      }
    }
  }
`
